import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import styled from 'styled-components'

import cygnusLoopNebula from '../images/cygnus_loop_nebula.jpeg'

const Container = styled.div`
  background-image: url(${cygnusLoopNebula});
  background-size: cover;
`
const AboutPage = () => (
  <Container>
    <Layout style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
      <h3>
        <Link style={{ boxShadow: 'none' }} to={'/'}>
          Matter and void
        </Link>
      </h3>

      <h1>In reality, nothing but atoms and the void.</h1>

      <p>This is the World Wide Website of Daniel Vingo.</p>

      <p>If you'd like to contact me, you can email me at dan 'at' danvingo dot com</p>

      <p>
        You can find some of my open source work on
        <a href="https://github.com/dvingo" target="_blank" rel="noreferrer">
          {' '}
          GitHub
        </a>
      </p>

      <p>
        I&rsquo;m a software developer living in New York, I use this web address as a playground to
        host projects and as a place to share some of my thoughts with the world.
      </p>

      <p>
        I&rsquo;ve learned much from the internet (both from humans and non-humans) and I&rsquo;d
        like to contribute back in my own small way.
      </p>

      <p>
        The domain name comes from Lucretius&rsquo; On the{' '}
        <a
          href="http://classics.mit.edu/Carus/nature_things.1.i.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Nature of Things
        </a>
        :
      </p>
      <blockquote>
        <p>
          To begin: since nature is shown to be twofold, consisting, in fact, of two far different
          things, matter, and space in which events occur, each must be single, absolute, and pure.
          For where there’s empty space (what we call “void”) there, matter is not; further, where
          matter is, there, in no possible way, can there be void. Prime bodies are solid, then, and
          without void. Now since there is void in created things it must be solid matter that hems
          it in. Nor can true reason prove that anything holds void concealed within, unless you
          grant that what contains it is pure solid matter.
        </p>

        <p>
          Further, it could be nothing but a meeting of matter that could contain the void in
          things. Thus matter, which consists of solid body, can last forever, though all else is
          destroyed. Then further, if there were no empty void, all would be solid; but if there
          were no atoms to fill whatever space they occupied, then All-that-Is would be mere space
          and void. There are two things, then, separate and distinct: atoms and void; the world is
          not simply full, nor empty, either. No, atoms do exist, that show where “empty” ends and
          “full” begins.
        </p>
      </blockquote>
      <p>A more terse version from Democritus:</p>
      <p>Everything is atoms and void, all else is opinion.</p>

      <p style={{ fontSize: '.8rem' }}>
        This site was built using
        <a href="https://www.gatsbyjs.com/docs" target="_blank" rel="noreferrer">
          {' '}
          gatsby.js
        </a>
      </p>

      <div>
        <p style={{ fontSize: '.5rem' }}>
          Cygnus Loop Nebula - credit{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.jpl.nasa.gov/images/pia15415-cygnus-loop-nebula"
          >
            NASA JPL
          </a>
        </p>
      </div>
    </Layout>
  </Container>
)

export default AboutPage
